import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback, useState } from 'react';
import { getCoefficientByAmount, getCoefficientByAmountV2 } from '../helpers/';

var useCalculator = function useCalculator(_ref) {
  var minDeposit = _ref.minDeposit,
      _ref$baseValue = _ref.baseValue,
      baseValue = _ref$baseValue === void 0 ? '' : _ref$baseValue;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hasError = _useState2[0],
      showError = _useState2[1];

  var _useState3 = useState(baseValue),
      _useState4 = _slicedToArray(_useState3, 2),
      value = _useState4[0],
      handleValue = _useState4[1];

  var _useState5 = useState(''),
      _useState6 = _slicedToArray(_useState5, 2),
      amount = _useState6[0],
      handleAmount = _useState6[1];

  var updateValue = useCallback(function (e) {
    var value = _typeof(e) === 'object' && e !== null ? e.target.value : e;
    var onlyNumbers = /^\d+(\.\d{0,2})?$/;
    if (!onlyNumbers.test(value)) value = value.slice(0, value.length - 1);
    if (value.length > 12) value = value.slice(0, 12);
    handleValue(value);
  }, []);

  var validateMinDeposit = function validateMinDeposit() {
    var isError = Number(value) < minDeposit;
    showError(isError);
    return isError;
  };

  var calculateAmount = useCallback(function () {
    if (Number(value) < minDeposit) {
      showError(true);
      return handleAmount('');
    }

    var result = Number(value) * getCoefficientByAmount(Number(value), minDeposit);
    handleAmount(parseFloat(result.toFixed(2)));
    showError(false);
  }, [value]);
  var calculateAmountV2 = useCallback(function () {
    if (Number(value) < minDeposit) {
      showError(true);
      return handleAmount('');
    }

    var result = Number(value) * getCoefficientByAmountV2(Number(value), minDeposit);
    handleAmount(parseFloat(result.toFixed(2)));
    showError(false);
  }, [value]);
  var handleEnterKeyPress = useCallback(function (e) {
    if (e.key === 'Enter') calculateAmount();
  }, [value]);
  var validateOnInput = useCallback(function (_ref2) {
    var target = _ref2.target;
    var value = target.value;
    var onlyNumbers = /^\d+(\.\d{0,2})?$/;
    if (!onlyNumbers.test(value)) target.value = value.slice(0, value.length - 1);
    if (value.length > 12) target.value = value.slice(0, 12);
  }, []);
  return {
    value: value,
    updateValue: updateValue,
    hasError: hasError,
    handleEnterKeyPress: handleEnterKeyPress,
    validateOnInput: validateOnInput,
    calculateAmount: calculateAmount,
    calculateAmountV2: calculateAmountV2,
    amount: amount,
    validateMinDeposit: validateMinDeposit
  };
};

export default useCalculator;